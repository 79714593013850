import React, { useState, useEffect } from 'react';
import * as d3 from 'd3';
// import styles from './graphStyles.module.scss';

import DATA from './pet.json';
import useThrottleResize from '../../../hooks/useThrottleResize';
import useEventListener from '../../../hooks/useEventListener';
import useDimensions, { getDimensions } from '../../../hooks/useDimensions';
import weightValues from './weightValues';
// import labeler from './labeler';

// labeler(d3);

const LABEL_OFFSETS = {
  'United Kingdom': 10,
  'South Korea': -20,
  Portugal: 10,
  Singapore: -10,
  Denmark: 12,
  Spain: -5,
  Australia: -10,
};

const CHART_MARGIN = 30;
const ORDINALS = ["None", "Very low", "Low", "Moderate", "High", "Very high"];
const priceToOrdinal = d3.scaleQuantile().domain([300, 1730]).range(ORDINALS.slice(1));

// const {
//   minValue, maxValue, minEase, maxEase,
// } = weightValues(DATA);

/**
 * X-axis: Value prop of market
 * Y-axis: Ease of entry to market
 * cirlce size: Volume
 * circle color: gradient range -- cost per scan
 */

function calculateGraphScale(width, height) {
  const PROPS = {
    x: "value",
    y: "ease",
    z: "PET Volume",
    c: "reimbursementOrdinal",
  };

  // const x = d3.scaleLinear().domain([250, 4630])
  //   .range([CHART_MARGIN, (width - CHART_MARGIN)]);
  const x = d3.scalePow().domain([250, 4630])
    .range([CHART_MARGIN, (width - CHART_MARGIN)]).exponent(0.5);
  // const x = d3.scalePow().domain([0.6, 1.9])
  //   .range([CHART_MARGIN * 2, (width - 2 * CHART_MARGIN)]).exponent(0.25);
  const y = d3.scaleLinear().domain([0, 5])
    .range([CHART_MARGIN, height - CHART_MARGIN]);
  const z = d3.scalePow().domain([0, 2000000]).range([6, 80]).exponent(0.5);
  const c = d3.scaleOrdinal().domain([-2, 0, 1, 2, 3, 4, 5, 6]).range(d3.schemeGreens[8]);

  // draw and style axes
  const xAxis = d3.axisBottom(x)
    .tickValues([])
    .tickSize(0);

  const yAxis = d3.axisLeft(y)
    .tickValues([])
    .tickSize(0);

  return {
    x,
    y,
    z,
    c,
    xAxis,
    yAxis,
    PROPS,
  };
}

function useDrawGraph(width, height, xLabel, yLabel, styles) {
  const { x, y, z, c, xAxis, yAxis, PROPS } = calculateGraphScale(width, height);


  useEffect(() => {
    const nodes = DATA.map(el => {
      const node = {};
      Object.assign(node, el);
      node.x = x(el[PROPS.x]);
      node.y = y(el[PROPS.y]);
      node.radius = z(el[PROPS.z]);
      node.color = c(el[PROPS.c]);
      return node;
    });


    // const labelsArray = nodes.map(node => {
    //   const { x, y, Country } = node;
    //   return { x, y, name: Country, width: 0, height: 0 }
    // })

    // prevent overlapss
    d3.forceSimulation(nodes)
      .force('collision', d3.forceCollide()
        // .radius(d => Math.max(d.radius + 1, d.Country.length * 5)))
        .radius(d => d.radius))
      .force('x', d3.forceX().x(d => d.x))
      .force('y', d3.forceY().y(d => d.y))
      .on('tick', renderChart);

    function renderChart() {
      // append and select all nodes
      const root = d3.select('svg');
      const tooltip = d3.select('#tooltip');

      root.select('#data-points').selectAll('circle').data(nodes).enter().append('circle')
        .on('mouseover', d => {
          const tooltipWidth = document.getElementById("tooltip").getBoundingClientRect().width;
          tooltip.style('left', `${Math.min(d3.event.pageX + 30, window.innerWidth - tooltipWidth - 50)}px`)
            .style('top', `${d3.event.pageY - 100}px`)
            .html(tooltipText(d));
          tooltip.transition().duration(500).style('opacity', 1);
        })
        .on('mouseout', () => {
          tooltip.style('left', '-500px').style('top', '-500px').style('opacity', 0);
        });

      // draw and style nodes
      const points = root.selectAll('circle');
      points
        .attr('cx', d => {
          d.x = Math.max(CHART_MARGIN * 2 + d.radius + 5, Math.min(d.x, width - CHART_MARGIN * 2 - d.radius - 5));
          return d.x;
        })
        .attr('cy', d => {
          d.y = Math.max(CHART_MARGIN + d.radius + 5, Math.min(d.y, height - CHART_MARGIN * 2 - d.radius - 5));
          return d.y;
        })
        .attr('r', d => d.radius)
        .attr('fill', d => d.color);

      // const labels = root.select('#data-points').selectAll('text')
      //   .data(labelsArray).enter()
      //   .append('text')
      //   .attr('class', styles.countryLabel)
      //   .attr('id', d => `label-${d.name}`)
      //   .attr('x', d => d.x)
      //   .attr('y', d => d.y)
      //   .text(d => d.name);

      // let n = 0;
      // labels.each(function (label) {
      //   const { width, height } = this.getBBox();
      //   labelsArray[n].width = width;
      //   labelsArray[n++].height = height;
      // });

      // d3.labeler()
      //   .label(labelsArray)
      //   .anchor(nodes)
      //   .width(width)
      //   .height(height)
      //   .start(100);


      // original:
      root.select('#data-points').selectAll('text')
        .data(nodes).enter()
        .append('text')
        .attr('class', styles.countryLabel)
        .attr('id', d => `label-${d.Country}`)
        .attr('width', d => d.radius)
        .text(d => d.Country);

      root.select('#data-points').selectAll('text')
        .attr('transform', d => {
          // console.log(d.Country, LABEL_OFFSETS[d.Country] || 0)
          const offset = document.getElementById(`label-${d.Country}`)
            .getBoundingClientRect().width / 2;
          const countryOffset = LABEL_OFFSETS[d.Country] || 0;
          return `translate(${-offset},${countryOffset})`;
        })
        .attr('x', d => d.x)
        .attr('y', d => d.y + 6)
        .text(d => d.Country);

      function tooltipText(d) {
        return `<div style="text-align:center">${d.Country}</div>
        Volume: ${d3.format(',')(d['PET Volume'])}<br />
        Scanners: ${d3.format(',')(d["PET Units"])}<br />
        Volume / scanner: ${d3.format(',')(d['Volume / scanner'])}<br /> 
        Volume / million ppl: ${d3.format(',')(d['Volume / million ppl'])}<br />
        Wait time: ${d['Wait time (range)']}<br />
        Reimbursements: ${priceToOrdinal(d['Avg charge / reimbursement (USD)'])}<br />
        Regulatory Difficulty: ${d['Regulatory Time']}<br />
      `;
        // Scanners per Million Ppl: ${d['Scanners / million ppl']}<br />
      }
    }


    // create axes
    const xA = d3.select('#x-axis')
      // .attr('transform', `translate(0, ${ height - 2 * CHART_MARGIN})`)
      .attr('transform', `translate(0, ${height / 2})`)
      .text('Annual Scan Volume')
      .call(xAxis);

    const yA = d3.select('#y-axis')
      .attr('x', CHART_MARGIN)
      .attr('y', CHART_MARGIN)
      .attr('transform', `translate(${width / 2}, ${height}) rotate(180)`) // rotating so that arrow head at upper end, translateY to bring line back down
      // .attr('transform', `translate(${ CHART_MARGIN * 2}, ${ height - CHART_MARGIN + 1}) rotate(180)`) // rotating so that arrow head at upper end, translateY to bring line back down
      .text('Scans per PET Machine')
      .call(yAxis);

    // add arrowheads
    xA.select('path').attr('marker-end', 'url(#arrowhead)');
    yA.select('path').attr('marker-end', 'url(#arrowhead)');

    // labels
    const xL = d3.select('#x-label')
      .text(xLabel)
      .attr('y', height - CHART_MARGIN)
      .attr('transform', `translate(0, ${- height / 2 + CHART_MARGIN / 2})`);
    // .attr('x', width / 2 + CHART_MARGIN);

    const yL = d3.select('#y-label')
      .text(yLabel)
      .attr('y', CHART_MARGIN * -2.5)
      .attr('transform', `rotate(90) translate(0, ${- width / 2 + CHART_MARGIN * 2})`);

    // adjust offset (have to be in dom before this can be calculated)
    const { width: xLabelWidth } = document.getElementById('x-label').getBoundingClientRect();
    const { height: yLabelLength } = document.getElementById('y-label').getBoundingClientRect();
    xL.attr('x', width * 3 / 4); // - xLabelWidth / 2
    yL.attr('x', height / 4 - yLabelLength / 2);
  }, [width, height]);
}

// add country labels
// tooltip shouldn't overflow screen
// dollar sign to x-axis
// legend for colors


export default function InternationalPET({ children, styles }) {
  const SCALE = { x: 1, y: 0.8 };
  const MIN_CHART_WIDTH = 500;
  const [INITIAL_WIDTH, INITIAL_HEIGHT] = getDimensions(1000, 800, 5, 0);
  // state for SVG size
  const [width, setWidth] = useState(INITIAL_WIDTH);
  const [height, setHeight] = useState(INITIAL_HEIGHT);
  const [labelsOff, toggleLabels] = useState('no');

  if (typeof window !== 'undefined') {
    useThrottleResize();
    useEventListener('throttledResize', () => useDimensions(setWidth, setHeight, { xOffset: 5 }));
  }

  const widthOrMinWidth = Math.max(MIN_CHART_WIDTH, width * SCALE.x);
  const actualWidth = Math.max(MIN_CHART_WIDTH, height * SCALE.y * 1.5);
  const actualHeight = height * SCALE.y;
  useDrawGraph(actualWidth, actualHeight, 'Value Proposition', 'Ease of Access', styles);

  const toggle = () => {
    toggleLabels(labelsOff === 'yes' ? 'no' : 'yes');
  };

  // console.log(data);
  return (
    <React.Fragment>
      <div className={styles.labelButtonContainer}>
        <button type="button" className={styles.labelButton} onClick={toggle}>Toggle Labels</button>
      </div>
      <div
        id="graph"
        className={styles.graphContainer}
        data-hidelabels={labelsOff}
      >
        <div id="tooltip" className={styles.tooltip} />
        <svg height={actualHeight} width={actualWidth} className={styles.canvas}>
          <defs>
            <marker
              id="arrowhead"
              refX={9}
              refY={5}
              markerWidth={20}
              markerHeight={20}
              orient="auto"
            >
              <path d="M 0 0 L 10 5 L 0 10 z" />
            </marker>
          </defs>
          <g id="data-points" />
          <text id="x-label" className={styles.label} />
          <text id="y-label" className={styles.label} />
          <g id="x-axis" className={styles.axis} />
          <g id="y-axis" className={styles.axis} />
          <g id="legend" className={styles.legend} />
        </svg>
        <div className={styles.legendContainer}>
          {children}
        </div>
      </div>
    </React.Fragment>
  );
}
