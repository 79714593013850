import React from 'react';
import Layout from '../../components/CleanLayout';
import SigninController from '../../components/login/SigninController.container';
import Signin from '../../components/login/Signin.container';
import SigninSwitch from '../../components/login/SigninSwitch';
import SignoutButton from '../../components/login/SignoutButton';
import EaseOfAccess from '../../components/graphing/PET/EaseOfAccess';
import stylesModule from '../../components/graphing/PET/graphStyles.module.scss';

const METADATA = [
  {
    name: 'description',
    content: 'International PET market',
  },
  {
    name: 'keywords',
    content: 'Subtle Medical, PET, international',
  },
];

const Graph = () => (
  <Layout pageTitle="International PET Market - Ease of Access" metadata={METADATA}>
    <SigninController />
    <SignoutButton />
    <h1 className="admin--title">International PET Market - Ease of Access</h1>
    <Signin />
    <SigninSwitch>
      <EaseOfAccess styles={stylesModule}>
        <div style={{ paddingTop: '0.5rem' }}>Legend</div>
        <div>Circle Size: PET Scan Volume</div>
        <div>Circle Color: Avg Reimbursement</div>
        <div style={{ paddingTop: '2rem' }} />
      </EaseOfAccess>
    </SigninSwitch>
  </Layout>
);

export default Graph;
