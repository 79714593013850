/*
Based on Volume,
Vol per scanner, (measure of how close to capacity a country is) up to 2, penalty of up to 1/2
Reimbursement, (up to 1.5) no penalty
Wait time, (up to 1.5) no penalty
Scanners / million pop (measure of room for growth, WHO targets 2 per M pop) up to 2, no penalty
*/
import * as d3 from 'd3';
import DATA from './pet.json';

const avgVolPerScanner =
  DATA.reduce((acc, curr) => acc + curr['Volume / scanner'], 0) / DATA.length;

function value(d) {
  const {
    'PET Volume': volume,
    'Volume / scanner': volumePerScanner,
    'Scanners / million ppl': scannersPerMillion,
    waitTimeOrdinal, // 1 to 5
    reimbursementOrdinal, // 1 to 5
  } = d;

  // If under WHO recommended 2 per M pop, gets a heavier weight up to 2x,
  // otherwise, ignore
  const scannersPerPopWeight = Math.max((2 - scannersPerMillion) / 2 + 1, 1);
  // if volume per scanner < avg, take a penalty up to 0.5
  // if volume per scanner > avg, take a heavier weight up to 2x
  const volumePerScannerWeight =
    volumePerScanner <= avgVolPerScanner
      ? Math.max(0.75, volumePerScanner / avgVolPerScanner)
      : Math.min(1.5, volumePerScanner / avgVolPerScanner);
  // high weight times and high costs, multiply by 10^-1
  const waitTimeWeight = 0.9 + waitTimeOrdinal / 10;
  const reimbursementWeight = reimbursementOrdinal / 2;

  return (
    waitTimeWeight * reimbursementWeight * scannersPerPopWeight * volumePerScannerWeight * volume
  );
}

/**
 * Assigns value prop based on wait times at country
 * @param {*} d
 */
function valueProp(d) {
  const {
    'Volume / scanner': volumePerScanner,
    'Scanners / million ppl': scannersPerMillion,
    waitTimeOrdinal, // 0 to 5
  } = d;

  // If under WHO recommended 2 per M pop, gets a heavier weight up to 2x,
  // otherwise, ignore
  const waitTimeWeight = 0.9 + waitTimeOrdinal / 10;
  const scannersPerPopWeight = Math.max((2 - scannersPerMillion) / 4 + 1, 1);
  const volumePerScannerWeight =
    volumePerScanner <= avgVolPerScanner
      ? Math.max(0.9, volumePerScanner / avgVolPerScanner)
      : Math.min(1.1, volumePerScanner / avgVolPerScanner);

  return volumePerScanner;
}

/*
Main consideration is time
-complexity probably doesn't matter that much if we hire an expert
to help us navigate the process
-cost is rapidly outstripped by the marginal revenue of our product
-risk is likely low because we're already approved in 2 largest markets
Reverse scale --> 0 is higher
*/
function ease(d) {
  const {
    regTimeOrd, regComplexityOrd, regCostOrd, regRiskOrd, Continent,
  } = d;

  // move European countries below the US
  if (!regTimeOrd && Continent === 'Europe') return 0.75 + regComplexityOrd / 100 + regCostOrd / 100 + regRiskOrd / 1000;

  return regTimeOrd * (1 + regComplexityOrd / 100 + regCostOrd / 100 + regRiskOrd / 1000);
}

export default function weightValues(data) {
  // define scales
  const ORDINALS = ['None', 'Very low', 'Low', 'Moderate', 'High', 'Very high'];

  const ordinalize = d3
    .scaleOrdinal()
    .domain(ORDINALS)
    .range([0, 1, 2, 3, 4, 5]);
  const ordinalizeReimbursement = d3
    .scaleQuantile()
    .domain([300, 1730])
    .range([1, 2, 3, 4, 5]);

  let minValue = Number.POSITIVE_INFINITY;
  let maxValue = Number.NEGATIVE_INFINITY;
  let minEase = Number.POSITIVE_INFINITY;
  let maxEase = Number.NEGATIVE_INFINITY;

  data.forEach(d => {
    d.waitTimeOrdinal = ordinalize(d['Wait time (range)']);
    d.reimbursementOrdinal = ordinalizeReimbursement(d['Avg charge / reimbursement (USD)']);
    d.regTimeOrd = ordinalize(d['Regulatory Time']);
    d.regComplexityOrd = ordinalize(d['Regulatory Complexity']);
    d.regCostOrd = ordinalize(d['Regulatory Cost']);
    d.regRiskOrd = ordinalize(d['Regulatory Risk']);
    d.value = valueProp(d);
    d.ease = ease(d);
    minValue = Math.min(minValue, d.value);
    maxValue = Math.max(maxValue, d.value);
    minEase = Math.min(minEase, d.ease);
    maxEase = Math.max(maxEase, d.ease);
    // console.log(d);
  });

  return {
    minValue,
    maxValue,
    minEase,
    maxEase,
  };
}
